import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { COMMUNITY_USER_LINK_BASE_FRAGMENT } from '@/graphql/_Fragments/CompanyUserLink/Base';

export const COMMUNITY_USER_AGENDA_ENTRY_FULL_FRAGMENT = `
  fragment communityUserAgendaEntryFullFragment on CommunityUserAgendaEntry {
    uid
    schemaCode
    targetType
    user {
     ...communityUserBaseFragment
    }
    link {
      ...communityUserLinkBaseFragment
    }
  }
  ${COMMUNITY_USER_LINK_BASE_FRAGMENT}
  ${COMMUNITY_USER_BASE_FRAGMENT}
`;
